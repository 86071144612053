import { useEffect, useRef, useState } from 'react';
import type { BalanceFragment } from '../useUserBalance/graphql/UserBalanceQuery.generated';

type Args = {
  balance?: BalanceFragment;
};

const useDelayedBalance = ({ balance }: Args) => {
  const [oldBalance, setOldBalance] = useState(0);
  const [delayedBalance, setDelayedBalance] = useState<BalanceFragment>();
  const timeout = useRef<NodeJS.Timer>();

  useEffect(() => {
    const newBalance = balance?.totalBalance;
    if (newBalance) {
      setOldBalance(newBalance);
      if (oldBalance === 0 || oldBalance > newBalance) {
        setDelayedBalance(balance);
      } else if (oldBalance < newBalance) {
        // eslint-disable-next-line immutable/no-mutation
        timeout.current = setTimeout(() => {
          setDelayedBalance(balance);
        }, 5000);
      }
    }

    return () => {
      if (newBalance && (oldBalance === 0 || oldBalance > newBalance)) {
        clearTimeout(timeout.current);
      }
    };
  }, [balance, oldBalance]);

  return delayedBalance;
};

export default useDelayedBalance;
