import useCurrencyCode from '~/client/hooks/currency/useCurrencyCode';
import useMoney from '~/client/hooks/currency/useMoney';
import useWalletCurrency from '~/client/hooks/currency/useWalletCurrency';
import useFeature from '~/client/hooks/feature/useFeature';
import useAgentData from '~/client/hooks/useAgentData';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import useUserBalance from '~/client/hooks/user/useUserBalance';

const useHoverView = (game: GameFragment) => {
  const { minBet } = game;
  const isLoggedIn = useIsLoggedIn();
  const isDepositToPlayAvailable = useFeature('depositToPlay');
  const { totalBalance } = useUserBalance();
  const { isMobile } = useAgentData();
  const { convertUnit } = useMoney();
  const currency = useWalletCurrency();
  const currencyCode = useCurrencyCode();
  const userBalance = convertUnit(
    totalBalance || 0,
    currency || 'BTC',
    currency || 'BTC',
    currencyCode || 'mBTC',
  );
  const isDepositToPlay =
    !isMobile &&
    isLoggedIn &&
    isDepositToPlayAvailable &&
    (!userBalance || userBalance < (minBet || 0));
  return { isDepositToPlay };
};

export default useHoverView;
