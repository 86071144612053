import GameBadge from './GameBadge';
import GameImageContainer from './GameImageContainer';
import GameThumbProps from './GameThumbProps';
import useGameThumb from './hooks/useGameThumb';
import Hover from './Hover';
import Img from '../../images/Img';
import FavouriteToggle from '../FavouriteToggle';

const SmallThumb: FC<GameThumbProps> = (props) => {
  const {
    game,
    thumb,
    width,
    showGrid,
    height,
    isHoverEnabled,
    isFavouriteToggleVisible,
    preloadImg,
    isBadge = true,
  } = useGameThumb({ ...props, isSmallThumbnail: true });
  return (
    <div className="relative shrink-0 cursor-pointer">
      {isFavouriteToggleVisible && (
        <FavouriteToggle
          game={game}
          className="absolute top-1.5 right-2 z-2 bg-transparent"
        />
      )}
      <>
        {isHoverEnabled && (
          <Hover game={game} gameHref={props.href ?? game.href ?? ''} />
        )}
        {isBadge && <GameBadge game={game} />}
        <GameImageContainer {...props}>
          <Img
            preload={preloadImg}
            src={thumb}
            width={width}
            height={height}
            alt={game.name}
            useDpr2x
            style={{
              width: showGrid ? '100%' : `${width}px`,
              height: showGrid ? 'auto' : `${height}px`,
            }}
            className="relative rounded-moon-s-sm"
          />
        </GameImageContainer>
      </>
    </div>
  );
};

export default SmallThumb;
