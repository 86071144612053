import { useCallback } from 'react';
import useCookieContext from '~/shared/hooks/useCookieContext';

const useCookie = <T>(
  name: string,
  initialValue?: T,
): [
  cookie: T,
  set: (value: T, options?: CookieOptions) => void,
  clear: (options?: CookieOptions) => void,
] => {
  const { getCookie, setCookie, removeCookie } = useCookieContext();
  const cookie = getCookieDefault(getCookie(name), initialValue) as T;

  const set = useCallback(
    (value: T, options: CookieOptions = {}) => {
      setCookie(name, value as string, options);
    },
    [name, setCookie],
  );
  const clear = useCallback(
    (options: CookieOptions = {}) => {
      removeCookie(name, options);
    },
    [name, removeCookie],
  );
  return [cookie, set, clear];
};

const getCookieDefault = <T>(value: T, initialValue?: T) => {
  if (typeof value === 'undefined') {
    return initialValue;
  }
  return value;
};
export default useCookie;
