import { Tag, mergeClassnames } from '@heathmont/moon-core-tw';
import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';
import useSite from '~/client/hooks/useSite';
import Translate from '~/client/shared/i18n/Translate';
import getBadge from './helpers';

type Props = { game: GameFragment };

const GameBadge: FC<Props> = ({ game }) => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  const { site } = useSite();
  const badge = getBadge(game, site);

  if (!badge) {
    return null;
  }

  return (
    <div
      className={mergeClassnames(
        'absolute left-1 top-1 z-2',
        newThumbsEnabled && 'left-2 top-2 sm:left-3 sm:top-3',
      )}
    >
      <Tag
        color="text-goten"
        bgColor={badge.color}
        size="2xs"
        className="px-1 sm:px-2"
      >
        <Translate
          id={`game.label_${badge.type}`}
          className="tracking-normal sm:tracking-wide"
        />
      </Tag>
    </div>
  );
};

export default GameBadge;
