import getCurrencyDisplayIcon from '~/client/hooks/currency/helpers/getCurrencyDisplayIcon';
import useTag from '~/client/hooks/feature/useTag';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';

const useCurrencyIcon = (currency?: string | null) => {
  const isUbtcEnabled = useTag('uBTC');
  const { walletCurrency = 'BTC' } = useCurrentUser();
  const finalCurrency = currency ?? walletCurrency;
  const icon = getCurrencyDisplayIcon(
    finalCurrency as CashierCurrency,
    isUbtcEnabled,
  );
  return icon;
};

export default useCurrencyIcon;
