import { useCallback, useState } from 'react';
import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';
import useRouter from '~/client/hooks/router/useRouter';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import useToastContext from '~/client/layout/DefaultLayout/LayoutContext/Toast/useToastContext';
import { FavouritesRecentsCountQueryDocument } from '~/client/layout/Sidebar/UserItems/hooks/useUserItems/FavouritesRecentsCountQuery.generated';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';
import useUpdateFavouritesCache from './useUpdateFavouritesCache';
import { useToggleFavouriteMutation } from '../useFavouriteToggle/ToggleFavouriteMutation.generated';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

const useFavouriteGame = (game?: SingleGameFragment) => {
  const { site } = useSite();
  const { asPath } = useRouter();
  const { showToast } = useToastContext();
  const { deviceType: type } = useAgentData();
  const [isFavourite, setIsFavourite] = useState(Boolean(game?.isFavourite));
  const [toggleFavourite] = useToggleFavouriteMutation();
  const updateCache = useUpdateFavouritesCache({ gameId: game?.id ?? '' });
  const newThumbsEnabled = useNewThumbnailsEnabled();
  const isFavouritesPage =
    asPath === '/games/favourite' || asPath === '/categories/favourite';

  const handleClick = useCallback(async () => {
    const refetchQueries = [
      {
        query: FavouritesRecentsCountQueryDocument,
        variables: {
          myFavouritesFilter: { type, site },
          myRecentsFilter: { type, site },
        },
      },
    ];
    if (game) {
      const { data } = await toggleFavourite({
        variables: { gameId: game.id },
        refetchQueries,
      });
      const errors = data?.casinoToggleFavourite?.errors;
      if (!errors) {
        const favourite =
          data?.casinoToggleFavourite?.favouriteGameNode?.isFavourite;
        const translateId = favourite
          ? 'casino.game_is_added_to_favourites'
          : 'casino.game_is_removed_from_favourites';
        setIsFavourite(!!favourite);
        showToast({
          message: translateId,
          variant: 'success',
          timeout: 3000,
        });
        window.dispatchEvent(new CustomEvent('favouriteToggle'));
        if (isFavouritesPage) {
          updateCache();
        }
      }

      sendEventToUmami(
        {
          handle: game.handle,
          status: isFavourite ? 'removed' : 'added',
        },
        'toggleFavorite',
      );
    }
    sendInteractionToGtm({
      eventCategory: 'image_button',
      eventAction: 'toggle_favorite',
      eventInfo: game?.id,
    });
  }, [
    game,
    isFavourite,
    isFavouritesPage,
    showToast,
    site,
    toggleFavourite,
    type,
    updateCache,
  ]);

  return {
    isFavourite,
    handleClick,
    newThumbsEnabled,
  };
};
export default useFavouriteGame;
