import useCashierLink from '~/client/hooks/feature/useCashierLink';
import Button from '~/client/shared/Button';
import Translate from '~/client/shared/i18n/Translate';

const DepositToPlayButton = () => {
  const { onClick } = useCashierLink({
    cashierPath: 'deposit',
  });

  return (
    <Button fullWidth variant="primary" size="xs" onClick={onClick}>
      <Translate id="general.deposit_to_play" />
    </Button>
  );
};

export default DepositToPlayButton;
