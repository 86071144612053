import { convertUnit, toInteger, fromInteger } from '@heathmont/money';

const initMoney: MoneyConvert = {
  convertUnit: (_) => 0,
  toInteger: (_) => 0,
  fromInteger: (_) => 0,
};

const useMoney = (skip?: boolean) =>
  skip ? initMoney : { convertUnit, toInteger, fromInteger };

export default useMoney;
