import NextLink from '~/client/shared/NextLink';
import type GameThumbProps from '../GameThumbProps';

const GameImageContainer: FC<GameThumbProps> = ({
  children,
  href,
  game,
  onClick,
  className,
}) => {
  if (onClick) {
    return (
      <div onClick={onClick} className={className}>
        {children}
      </div>
    );
  }
  return (
    <NextLink href={href ?? game.href ?? ''}>
      <div className={className}>{children}</div>
    </NextLink>
  );
};

export default GameImageContainer;
