import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import useCurrencyIcon from './useCurrencyIcon';
import useMoneyValue from './useMoneyValue';

type Options = {
  currency?: CashierCurrency;
  coinInSatoshi?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const useCurrencyDisplay = (amount: number, options?: Options) => {
  const { walletCurrency = 'BTC' } = useCurrentUser();
  const { currency = walletCurrency } = options ?? {};
  const money = useMoneyValue(amount, currency, options ?? {}) || '0.00';
  const icon = useCurrencyIcon(currency);
  return {
    money,
    icon,
    moneyIcon: `${money} ${icon}`,
    moneyIconSign: getMoneyIconSign(amount, icon, money),
  };
};

const getMoneyIconSign = (amount: number, icon: string, money?: string) => {
  const sign = amount > 0 ? '+' : '';
  return `${sign} ${money} ${icon}`;
};

export default useCurrencyDisplay;
