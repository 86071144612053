import { useState } from 'react';
import HoverView from './HoverView';

type Props = { game: GameFragment; gameHref: string };

const ThumbnailHover: FC<Props> = ({ game, gameHref }) => {
  const [visible, setVisible] = useState(false);
  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);
  return (
    <div
      className="absolute inset-0 z-1"
      onMouseEnter={handleMouseEnter}
      onTouchStart={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchEnd={handleMouseLeave}
    >
      {visible && <HoverView game={game} gameHref={gameHref} />}
    </div>
  );
};

export default ThumbnailHover;
