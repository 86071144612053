const getBadge = (game: GameFragment, site: Site): GameBadgeType | null => {
  if (game?.isTrending) {
    return {
      type: 'trending',
      color: 'bg-chichi',
    };
  }
  if (game?.isHot) {
    return {
      type: 'hot',
      color: 'bg-popo',
    };
  }
  if (game?.isNew) {
    return {
      type: 'new',
      color: site === 'empire' ? 'bg-cell' : 'bg-roshi',
    };
  }
  if (game?.isHighRoller) {
    return {
      type: 'high-roller',
      color: 'bg-popo',
    };
  }
  return null;
};

export default getBadge;
