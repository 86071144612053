import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import useCurrencyConfig from '~/client/hooks/currency/useCurrencyConfig';
import useAgentData from '~/client/hooks/useAgentData';
import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import type { currencyConversion } from '~/schema/public';
import {
  GamesListQueryDocument,
  GamesListQuery,
  GamesListQueryVariables,
} from './../../../../../../shared/game/GameList/graphql/GamesListQuery.generated';

type Args = {
  gameId: string;
};

const useUpdateFavouritesCache = ({ gameId }: Args) => {
  const { cache } = useApolloClient();
  const { site } = useSite();
  const { deviceType: type, isDesktop } = useAgentData();
  const { code: language } = useLanguage();
  const { name } = useCurrencyConfig();
  const currency = name as currencyConversion;

  const updateCache = useCallback(() => {
    const variables: GamesListQueryVariables = {
      first: isDesktop ? 30 : 12,
      language,
      currency,
      filter: {
        site,
        type,
      },
      favouritesFilter: { site, type },
      recentsFilter: { site, type },
      skipFavourites: false,
      skipRecents: true,
      skipRegular: true,
      sort: { popular: -1, language },
    };

    const data = cache.readQuery<GamesListQuery>({
      variables,
      query: GamesListQueryDocument,
    });

    const edges =
      data?.casino?.myFavourites?.edges?.filter(
        ({ node }) => node?.id !== gameId,
      ) ?? {};
    const newFavourites = {
      ...data?.casino?.myFavourites,
      edges,
      totalCount: Object.keys(edges).length,
    };
    cache?.writeQuery({
      query: GamesListQueryDocument,
      variables,
      data: {
        casino: { ...data?.casino, myFavourites: newFavourites },
      },
    });
  }, [cache, currency, gameId, isDesktop, language, site, type]);

  return updateCache;
};

export default useUpdateFavouritesCache;
