import floorDecimals from './floorDecimals';

const fallbackMax = 2;
const absoluteMaxDigits = 20;

const sanitizeMinMaxFractionDigits = (min: number, max?: number) => {
  const sanitizedMin = Math.max(0, Math.min(absoluteMaxDigits, min));
  const sanitizedMax = Math.max(
    sanitizedMin,
    Math.max(0, Math.min(absoluteMaxDigits, max || fallbackMax)),
  );
  return {
    minimumFractionDigits: sanitizedMin,
    maximumFractionDigits: sanitizedMax,
  };
};

const formatNumber = (
  value: number,
  locale: string,
  minimumFractionDigits: number,
  maximumFractionDigits?: number,
) => {
  const result = floorDecimals(
    value,
    minimumFractionDigits,
    maximumFractionDigits,
  );
  if (!result) {
    return undefined;
  }
  const sanitizedMinMax = sanitizeMinMaxFractionDigits(
    minimumFractionDigits,
    maximumFractionDigits,
  );
  return new Intl.NumberFormat(locale, sanitizedMinMax).format(result);
};
export default formatNumber;
