import { updateSearchParams } from '@heathmont/kasino';
import useCurrencyConfig from '../../currency/useCurrencyConfig';
import useRouter from '../../router/useRouter';
import useSite from '../../useSite';
import useFeature from '../useFeature';
import useCashierStore from '@/client/hooks/useCashierStore';

type Args = {
  cashierPath?: string;
};

type ReturnProps = {
  link: string;
  onClick?: () => void;
  open?: () => void;
};

const useCashierLink = ({ cashierPath = 'home' }: Args = {}): ReturnProps => {
  const { type, name } = useCurrencyConfig();

  const { asPath } = useRouter();
  const { setOpen } = useCashierStore();
  const { isSbio } = useSite();
  const isCashierEnabled = useFeature('isNewCashierEnabled');
  const cashierLink = asPath.includes('?modal=menu')
    ? `${asPath}&c=${cashierPath}`
    : `${asPath}?c=${cashierPath}`;

  if (isSbio && !isCashierEnabled) {
    return {
      link: '/profile/funds',
    };
  }

  const getDefaultPath = () => {
    const isPathDeposit = cashierPath === 'deposit';
    if (isPathDeposit && type === 'coin') {
      return `${cashierPath}-${name}`;
    }
    return 'home';
  };

  const isPathDepositOptions = cashierPath.startsWith('deposit_options');
  const cashierPage = isPathDepositOptions ? cashierPath : getDefaultPath();
  return {
    link: cashierLink,
    onClick: () => {
      updateSearchParams({
        key: 'c',
        value: cashierPage,
        method: 'replace',
      });
      setOpen(true);
    },
    open: () => {
      updateSearchParams({
        key: 'c',
        value: cashierPath,
        method: 'replace',
      });
      setOpen(true);
    },
  };
};

export default useCashierLink;
