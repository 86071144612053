import formatNumber from './formatNumber';

const formatFloat = (
  value: number,
  locale: string,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
) =>
  formatNumber(
    value,
    locale,
    minimumFractionDigits ?? 2,
    maximumFractionDigits,
  );

export default formatFloat;
