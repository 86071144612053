const getUnitName = (
  currency: CashierCurrency,
  isUbtcEnabled: boolean,
): CashierCurrency | BtcUnit => {
  if (currency === 'BTC') {
    if (isUbtcEnabled) {
      return 'uBTC';
    }
    return 'mBTC';
  }
  return currency;
};
export default getUnitName;
