import { Button } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';
import DarkThemeProvider from '~/client/shared/ThemeProviders/DarkThemeProvider';
import DepositToPlayButton from './DepositToPlayButton';
import Container from './styles/Container';

type Props = { gameHref: string };

const DepositToPlay: FC<Props> = ({ gameHref }) => (
  <DarkThemeProvider>
    <Container>
      <DepositToPlayButton />
      <NextLink href={gameHref}>
        <Button variant="ghost" fullWidth size="xs">
          <p className="text-moon-12 text-goten">
            <Translate id="general.open_the_game" />
          </p>
        </Button>
      </NextLink>
      <p className="text-moon-14 text-bulma" />
    </Container>
  </DarkThemeProvider>
);

export default DepositToPlay;
