import useCurrencyDisplay from '~/client/hooks/currency/useCurrencyDisplay';
import type { BalanceFragment } from '~/client/hooks/user/useUserBalance/graphql/UserBalanceQuery.generated';

type Args = {
  balance?: BalanceFragment;
};

const useDisplayAmount = ({ balance }: Args) => {
  const { currency, totalBalance = 0 } = balance ?? {};
  const displayAmount = useCurrencyDisplay(totalBalance, {
    currency: currency as CashierCurrency,
  });
  return displayAmount;
};

export default useDisplayAmount;
