import type { CashierCurrency } from '~/schema/public';
import useCurrencyConfig from './useCurrencyConfig';
import useWalletCurrency from './useWalletCurrency';

type Options = { noUnit?: boolean };

const useCurrencyCode = (options?: Options): CashierCurrency | BtcUnit => {
  const { noUnit } = options ?? {};
  const walletCurrency = useWalletCurrency();
  const config = useCurrencyConfig();
  if (noUnit) {
    return config?.name ?? walletCurrency;
  }
  return config?.unit ?? config?.name ?? walletCurrency;
};

export default useCurrencyCode;
