import DefaultHover from './DefaultHover';
import DepositToPlay from './DepositToPlay';
import useHoverView from './hooks/useHoverView';

type Props = { gameHref: string; game: GameFragment };

const HoverView: FC<Props> = ({ gameHref, game }) => {
  const { isDepositToPlay } = useHoverView(game);
  if (isDepositToPlay) {
    return <DepositToPlay gameHref={gameHref} />;
  }
  return <DefaultHover gameHref={gameHref} key="default-hover" />;
};

export default HoverView;
