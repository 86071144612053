import type { MouseEvent } from 'react';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';

type Props = {
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

const FavouriteToggleContainer: FC<Props> = ({
  children,
  handleClick,
  className,
}) => (
  <Button
    size="xs"
    onClick={handleClick}
    className={mergeClassnames('p-0 w-5 h-5 bg-goten', className)}
    data-testid="favourite-toggle"
  >
    {children}
  </Button>
);

export default FavouriteToggleContainer;
