import useFeatures from '~/client/hooks/feature/useFeatures';
import useCookie from '~/shared/hooks/useCookie';

const useFeature = <T extends Feature>(flag: T) => {
  const features = useFeatures();
  const [overrideCookie] = useCookie(`override_ff_${flag}`);

  if (!features) {
    return undefined;
  }

  return overrideCookie === undefined || overrideCookie === ''
    ? features[flag]
    : overrideCookie === 'true';
};

export default useFeature;
