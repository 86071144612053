import useTags from '~/client/hooks/feature/useTags';

const useTag = <T extends UserTag>(tag: T) => {
  const tags = useTags();
  if (!tags) {
    return false;
  }
  return Boolean(tags[tag]);
};

export default useTag;
