import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import isSsr from '~/shared/utils/isSsr';
import { useMyBalanceChangedSubscription } from './graphql/MyBalanceChangedSubscription.generated';
import { useMyDepositAddressUpdatedSubscription } from './graphql/MyDepositAddressUpdatedSubscription.generated';
import { useUserBalanceQuery } from './graphql/UserBalanceQuery.generated';
import useDisplayAmount from './useDisplayAmount';
import useDelayedBalance from '../useDelayedBalance';

const useUserBalance = (wallet?: CashierCurrency) => {
  const { user, walletCurrency } = useCurrentUser();
  const { loading, data } = useUserBalanceQuery({
    skip: !walletCurrency,
    variables: { first: 1, walletCurrency: wallet ? wallet : walletCurrency },
  });
  const balance = data?.banking?.myBalances?.edges?.[0]?.node;
  const delayedBalance = useDelayedBalance({ balance });

  useMyBalanceChangedSubscription({
    skip: isSsr(),
  });

  useMyDepositAddressUpdatedSubscription({
    variables: { walletCurrency },
    skip: isSsr(),
  });

  const { realBalance, totalBonusBalance, cashierBalance, lockedRealBalance } =
    balance || {};

  const getBalanceType = () => {
    if (balance?.currency !== delayedBalance?.currency) {
      return balance;
    }
    return delayedBalance || balance;
  };

  const { money, icon } = useDisplayAmount({ balance: getBalanceType() });
  return {
    loading,
    user,
    money,
    icon,
    realBalance,
    totalBalance: cashierBalance,
    totalBonusBalance,
    lockedRealBalance,
  };
};

export default useUserBalance;
