import formatFloat from '~/client/hooks/currency/helpers/formatFloat';
import useLanguage from '~/client/hooks/useLanguage';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import useCurrencyDigits from './useCurrencyDigits';
import useParsedDisplayAmount from './useParsedDisplayAmount';

type Options = {
  coinInSatoshi?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const useMoneyValue = (
  amount: number,
  currency: CashierCurrency | string,
  options: Options,
) => {
  const { minimumFractionDigits, maximumFractionDigits } = options;
  const { code } = useLanguage();
  const { walletCurrency } = useCurrentUser();
  const effectiveCurrency =
    (currency as CashierCurrency) ?? walletCurrency ?? 'BTC';
  const digits = useCurrencyDigits();
  const parsedAmount = useParsedDisplayAmount({
    amount,
    currency: effectiveCurrency,
  });
  const money = formatFloat(
    parsedAmount,
    code,
    minimumFractionDigits,
    maximumFractionDigits ?? digits.max,
  );
  return money;
};

export default useMoneyValue;
