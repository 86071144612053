import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';
import useAgentData from '~/client/hooks/useAgentData';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import type GameThumbProps from '../GameThumbProps';

const useGameThumb = ({
  game,
  isHoverEnabled,
  hideFavourite = false,
  width,
  showGrid,
  height,
  big,
  light,
  preloadImg,
  isBadge,
  onClick,
  isMinMaxBetVisible,
  isCarousel,
  isSmallThumbnail = false,
}: GameThumbProps) => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  const { isDesktop } = useAgentData();
  const newThumbnailUrl = game.thumbUrlTab || game.thumbUrl;
  const thumb =
    newThumbsEnabled && !isSmallThumbnail ? newThumbnailUrl : game.thumbUrl;
  const isLoggedIn = useIsLoggedIn();
  const isFavouriteToggleVisible = isLoggedIn && !hideFavourite;
  return {
    game,
    thumb,
    width,
    showGrid,
    height,
    big,
    light,
    preloadImg,
    isBadge,
    isFavouriteToggleVisible,
    isHoverEnabled: isHoverEnabled && isDesktop && !onClick,
    isMinMaxBetVisible,
    isDesktop,
    newThumbsEnabled,
    isCarousel,
  };
};

export default useGameThumb;
