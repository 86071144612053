import useTag from '~/client/hooks/feature/useTag';
import type { CashierCurrency } from '~/schema/public';
import { useListCurrencyConfigQuery } from './graphql/ListCurrenciesConfigQuery.generated';
import getLowBalanceTrigger from './helpers/getLowBalanceTrigger';
import getUnitName from './helpers/getUnitName';
import useSite from '../../useSite';
import useWalletCurrency from '../useWalletCurrency';

const useCurrencyConfig = () => {
  const currency = useWalletCurrency();
  const { site } = useSite();
  const isUbtcEnabled = useTag('uBTC');
  const { data } = useListCurrencyConfigQuery({
    variables: {
      input: {
        site: site as CashierSite,
        name: currency as CashierCurrency,
      },
    },
  });
  const edges = data?.cashier?.currencyConfig?.edges ?? [];
  const { node } = edges[0] ?? {};
  if (!node?.name) {
    return {} as CashierConfig;
  }
  const { name, type } = node;
  const newNode = {
    ...node,
    icon: name, //Todo get icon from config
    type: type === 'banking' ? 'fiat' : 'coin',
    unit: getUnitName(name, isUbtcEnabled),
    lowBalanceTrigger: getLowBalanceTrigger(name),
  };
  return newNode as CashierConfig;
};

export default useCurrencyConfig;
