const getCurrencyDisplayIcon = (
  currency?: CashierCurrency,
  isUbtcEnabled = false,
) => {
  if (currency === 'BTC') {
    if (isUbtcEnabled) {
      return getIcon('uBTC');
    }
    return getIcon('mBTC');
  }
  return getIcon(currency);
};

const getIcon = (currency?: CashierCurrency | BtcUnit | 'satoshi' | null) => {
  switch (currency) {
    case 'satoshi':
      return 'BTC';
    case 'uBTC':
      return 'µBTC';
    case undefined:
    case null:
      return '';
    default:
      return currency;
  }
};

export default getCurrencyDisplayIcon;
