import { useEffect, useRef } from 'react';
import { useSportsbetPreferencesQuery } from '~/shared/graphql/user/SportsbetPreferencesQuery.generated';
import Cookie from '~/shared/utils/Cookie';
import useUserPreferenceId from '../../cookies/useUserPreferenceId';
import useSite from '../../useSite';
import useCashierStore from '@/client/hooks/useCashierStore';

const useSportsbetPreferences = () => {
  const wasCasierOpened = useRef(false);
  const [userPreferenceId] = useUserPreferenceId();
  const { site, isBridgeSite } = useSite();
  const { isOpen: isCashierOpen } = useCashierStore();
  const { data, refetch } = useSportsbetPreferencesQuery({
    variables: { site, userPreferenceId },
    skip: !isBridgeSite,
  });
  const { currency, wallets, id, colorScheme } =
    data?.sportsbetPreferences?.getUserPreferencesById || {};

  useEffect(() => {
    if (id) {
      Cookie.set('userPreferenceId', id);
    }
    if (wasCasierOpened.current && !isCashierOpen) {
      refetch();
    }

    // eslint-disable-next-line immutable/no-mutation
    wasCasierOpened.current = isCashierOpen;
  }, [isCashierOpen, refetch, id]);

  return { currency, wallets, userPreferenceId: id, colorScheme };
};

export default useSportsbetPreferences;
