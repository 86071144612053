import Digits from '~/client/hooks/currency/helpers/Digits';
import useCurrencyConfig from '~/client/hooks/currency/useCurrencyConfig';

const useCurrencyDigits = () => {
  const config = useCurrencyConfig();
  return {
    min: Digits.MINIMUM,
    max: config.maximumFractionDigits ?? Digits.MAXIMUM,
  };
};

export default useCurrencyDigits;
