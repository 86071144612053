import type { CashierCurrency } from '~/schema/public';

const getLowBalanceTrigger = (currency: CashierCurrency) => {
  switch (currency) {
    case 'BTC':
      return 0.00025;
    case 'BNB':
      return 0.018;
    case 'ETH':
      return 0.003;
    case 'USDT':
    case 'BUSD':
    case 'DAI':
    case 'TON':
    case 'USDC':
    case 'JPY':
      return 5;
    default:
      return undefined;
  }
};
export default getLowBalanceTrigger;
