import PlayImage from './PlayImage';
import HoverGameLink from '../shared/HoverGameLink';

type Props = { gameHref: string };

const DefaultHover: FC<Props> = ({ gameHref }) => (
  <HoverGameLink href={gameHref}>
    <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-moon-s-sm bg-bulma/70 opacity-0 transition hover:opacity-100">
      <PlayImage />
    </div>
  </HoverGameLink>
);

export default DefaultHover;
