import useSite from '~/client/hooks/useSite';
import Cookie from '~/shared/utils/Cookie';
import useFetchUser from './useFetchUser';

const useCurrentUser = () => {
  const user = useFetchUser();
  const { siteConfig } = useSite();
  const colorScheme = normalizeColorScheme(siteConfig);
  return { colorScheme, ...user };
};

const normalizeColorScheme = (siteConfig: SiteConfig): ColorScheme => {
  const cookieTheme = Cookie.get('theme') as ColorScheme | undefined;
  if (cookieTheme === undefined) {
    return siteConfig.defaultColorScheme;
  }
  return cookieTheme;
};

export default useCurrentUser;
