import type { MouseEvent } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { GenericStar } from '@heathmont/moon-icons-tw';
import useFavouriteGame from '~/client/components/Game/GameView/shared/hooks/useFavouriteGame';
import FavouriteToggleContainer from './FavouriteToggleContainer';

type Props = {
  handleClick?: (event: MouseEvent<HTMLDivElement>) => void;
  game: GameFragment;
  className?: string;
};

const FavouriteToggle: FC<Props> = ({ game, className }) => {
  const { isFavourite, handleClick, newThumbsEnabled } = useFavouriteGame(game);
  return (
    <FavouriteToggleContainer handleClick={handleClick} className={className}>
      <GenericStar
        className={mergeClassnames(
          'text-goten text-moon-32 fill-transparent flex-shrink-0',
          isFavourite && 'fill-krillin',
          newThumbsEnabled && 'text-moon-20',
          newThumbsEnabled && !isFavourite && 'fill-popo',
        )}
      />
    </FavouriteToggleContainer>
  );
};

export default FavouriteToggle;
