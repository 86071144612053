import { convertUnit, fromInteger } from '@heathmont/money';
import useTag from '~/client/hooks/feature/useTag';

type Props = {
  amount: number;
  currency: CashierCurrency;
  coinInSatoshi?: boolean;
};

const useParsedDisplayAmount = ({ amount, currency, coinInSatoshi }: Props) => {
  const isUbtcEnabled = useTag('uBTC');
  if (!coinInSatoshi) {
    if (currency === 'BTC') {
      if (isUbtcEnabled) {
        return convertUnit(amount, currency, 'BTC', 'uBTC');
      }
      return convertUnit(amount, currency, 'BTC', 'mBTC');
    }
    return amount;
  }
  if (currency === 'BTC') {
    if (isUbtcEnabled) {
      return fromInteger(amount, currency, 'uBTC');
    }
    return fromInteger(amount, currency, 'mBTC');
  }
  return fromInteger(amount, currency);
};

export default useParsedDisplayAmount;
