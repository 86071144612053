import useAppContext from '~/shared/context/AppContext/useAppContext';
import useCurrentUser from './useCurrentUser';

const useIsLoggedIn = () => {
  const { user: appRouterUser } = useAppContext();
  const { user } = useCurrentUser();
  return appRouterUser?.isLoggedIn ?? !!user;
};

export default useIsLoggedIn;
