import Img from '~/client/shared/images/Img';
import getCdnIcon from '~/shared/cdn/getCdnIcon';

const PlayImage = () => (
  <div className="grid h-12 w-12 items-center justify-center rounded-full bg-goten/25 mix-blend-normal backdrop-blur-sm">
    <Img
      width={20}
      height={20}
      alt="play game"
      src={getCdnIcon('play-triangle')}
    />
  </div>
);

export default PlayImage;
