import type { HTMLProps } from 'react';
import { Button as MoonButton, mergeClassnames } from '@heathmont/moon-core-tw';
import type ButtonSettingsProps from '@heathmont/moon-core-tw/lib/button/private/types/ButtonSettingsProps';
import useSite from '~/client/hooks/useSite';

type Props = Omit<HTMLProps<HTMLButtonElement>, 'size'> &
  ButtonSettingsProps & {
    className?: string;
    as?: React.ElementType;
  };

const Button: FC<Props> = ({
  children,
  className,
  variant = 'primary',
  ...props
}) => {
  const { isBitcasino, isSportsbet, isSportsbetMx } = useSite();
  return (
    <MoonButton
      data-testid="tw-button"
      className={mergeClassnames(
        (isBitcasino || isSportsbet || isSportsbetMx) &&
          variant === 'tertiary' &&
          'text-bulma',
        className,
      )}
      variant={variant}
      {...props}
    >
      {children}
    </MoonButton>
  );
};

export default Button;
