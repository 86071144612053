const floorDecimals = (
  value: number,
  minimumFractionDigits: number,
  maximumFractionDigits?: number,
) => {
  if (!value) {
    return undefined;
  }
  const decimalsShowing = maximumFractionDigits
    ? maximumFractionDigits
    : minimumFractionDigits;

  const roundedValue = Number(
    `${Math.floor(
      parseFloat(`${value}e${decimalsShowing}`),
    )}e-${decimalsShowing}`,
  );
  return roundedValue;
};

export default floorDecimals;
