import useSite from '~/client/hooks/useSite';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import { useCurrentUserQuery } from '~/shared/graphql/user/CurrentUserQuery.generated';
import useToken from '~/shared/hooks/useToken';
import useSportsbetPreferences from '../../currency/useSportsbetPreferences';

const useFetchUser = () => {
  const { site } = useSite();
  const { user: appRouterUser } = useAppContext();
  const [token] = useToken();
  const { currency: sbCurrency, userPreferenceId } = useSportsbetPreferences();
  const { data, error, loading } = useCurrentUserQuery({
    variables: { site },
    skip: !token || !!appRouterUser,
  });
  const walletCurrency = (appRouterUser?.walletCurrency ??
    sbCurrency ??
    data?.features?.walletCurrency) as Maybe<CashierCurrency>;
  const { id, user, profile, countryInfo } =
    appRouterUser ?? data?.userManagement ?? {};

  return {
    id,
    user,
    profile,
    countryInfo,
    walletCurrency,
    error,
    loading,
    token,
    userPreferenceId,
  };
};

export default useFetchUser;
