import { ThemeProvider } from 'styled-components';
import useSite from '~/client/hooks/useSite';
import getTheme from '~/shared/utils/theme/getTheme';

const THEMES_MAP: SiteRecord<Theme> = {
  livecasino: getTheme('livecasinoDark'),
  sportsbet: getTheme('sportsbetDark'),
  bitcasino: getTheme('bitcasinoDark'),
  sportsbetmx: getTheme('sportsbetDark'),
  empire: getTheme('empireDark'),
};

const DarkThemeProvider: FC = ({ children }) => {
  const { site } = useSite();
  const theme = THEMES_MAP[site];
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DarkThemeProvider;
