import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import { useTagsQuery } from '~/shared/graphql/tags/TagsQuery.generated';

const DEFAULT_VALUE: UserTags = { id: '', __typename: 'Features' };

const useTags = (): UserTags => {
  const isLoggedIn = useIsLoggedIn();
  const { data } = useTagsQuery({ skip: !isLoggedIn });

  return data?.features ?? DEFAULT_VALUE;
};

export default useTags;
